import { AxiosResponse } from 'axios'
import axios from '../../interceptor/interceptor'

export interface OptimizeByAiFunctionPropsType {
  apiEnum: 'perform' | 'performsubscribe' | 'performunsubscribe'
  code: string
  pluginName: string
  actionOrTriggerName: string
  actionVersionId: string
  context: any
}
const URL = process.env.REACT_APP_API_BASE_URL

export async function DhOptimizeByAi(props: OptimizeByAiFunctionPropsType) {
  const { apiEnum, code, pluginName, actionOrTriggerName, context } = props
  const data = {
    apiSlug: apiEnum,
    code,
    pluginName,
    actionOrTriggerName,
    context
  }
  const response: AxiosResponse<{ data: { [key: string]: string } }> = await axios.post(`${URL}/openai/dh/optimizeCode`, data)
  return response.data?.data
}

export async function DhGetActionsTriggersSuggestionApi(pluginName: string, pluginId: string) {
  try {
    const data = {
      pluginName,
      pluginId
    }
    const aiSuggestedActionsTriggers: AxiosResponse<{
      data: { actions: { name: string; description: string }[]; triggers: { name: string; description: string; type: string }[] }
    }> = await axios.post(`${URL}/openai/dh/getActionTriggersSuggestions`, data)
    return {
      isSuccess: true,
      data: aiSuggestedActionsTriggers?.data?.data
    }
  } catch (error) {
    return {
      isSuccess: false,
      data: []
    }
  }
}

export async function DhGetBrandDetailsFromAiAndTOBApi(pluginId: string, pluginDomain: string, pluginName: string) {
  try {
    const data = {
      pluginDomain,
      pluginName,
      pluginId
    }
    const response: AxiosResponse<{ data: { iconurl: string; brandcolor: string } }> = await axios.post(
      `${URL}/openai/dh/getBrandDetails`,
      data
    )
    return {
      isSuccess: true,
      data: response.data?.data
    }
  } catch (error) {
    return {
      isSuccess: false,
      data: {}
    }
  }
}

export async function DHAuthConfigByAiApi(data: {
  authType: 'Basic' | 'Auth2.0'
  serviceName: string
  libraries: any
  stepName?: string[]
  currentCode?: string
  context?: any
}) {
  try {
    const response = await axios.post(`${URL}/openai/dh/auth`, data)
    return response?.data?.data
  } catch (error) {
    return {}
  }
}
