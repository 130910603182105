import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Avatar from '@mui/material/Avatar'
import { Button, Typography, Box, CircularProgress } from '@mui/material'
import AuthFields from '../../components/plugin/pluginComponents/basicAuth/authFields'
import { errorToast } from '../../components/customToast'
import { AuthLevel } from './authLevelPage.tsx'
import { getAuthToken, getCompanyBilling, redirectToServiceAuthPage, storeAuthDetailsToRedis, updateCompanyBilling } from '../../api/index'
import config from '../../config'
import { generateRandomString } from '../../utils/utilities'
import { MiscTypes } from '../../enums'
import selectActiveUser from '../../store/user/userSelector'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import AuthTokenDescription from './oAuthTokenDescription.tsx'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

interface AuthenticateAuth2PluginProps {
  authInfo: any
  plugData: any
  scopes: string[]
  backButton: boolean
  actionId: string
  origin: string
}
/**
 * Extracts all query parameters from the current URL.
 *
 * @returns {Object} An object containing all query parameters.
 */
function AuthenticateAuth2Plugin({
  authInfo,
  plugData,
  scopes,
  backButton,
  actionId,
  orgid,
  projectid,
  scriptid,
  userid,
  level,
  authidtoupdatetoken,
  save,
  actionid,
  mode,
  isUpdate,
  message,
  buttonMessage,
  redirectUrl,
  origin = ''
}: AuthenticateAuth2PluginProps) {
  const navigate = useNavigate()
  const [authData, setAuthData] = useState({})
  const authLevelId = level === 'project' ? projectid : orgid
  const [authLevel, setAuthLevel] = useState(authLevelId)
  const [description, setDescription] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const userData = useCustomSelector((state) => selectActiveUser(state))

  useEffect(() => {
    let fields = []
    const { authentication } = authInfo?.authfields
    fields = authentication?.fields || []
    let allKeysPresent = fields?.length > 0 // so that if there is no auth fields, do not auto invoke handleAuthVerifyAuth
    const paramsAuthData: any = {}
    const queryParams = new URLSearchParams(window.location.search)

    fields?.forEach((keyObj) => {
      if (queryParams.has(keyObj.key)) {
        paramsAuthData[keyObj.key] = queryParams.get(keyObj.key)
      } else {
        allKeysPresent = false
      }
    })

    // Use allKeysPresent to decide further action
    if (allKeysPresent) {
      handleAuth2VerifyAuth(paramsAuthData)
    }
  }, [])

  const handleBackButton = async () => {
    if (!backButton) return
    let url = `${config.servicePage}/${plugData?.rowid}`
    const { search } = window.location

    const queryParams = new URLSearchParams(search)
    queryParams.delete('actionid')

    const updatedSearch = queryParams.toString()
    if (updatedSearch) {
      url += `?${updatedSearch}`
    }

    navigate(url, {
      state: {
        pluginDetails: plugData
      }
    })
  }

  // oauth2.0 flow
  const authorizationCode = async (authDetails: any) => {
    try {
      const redisId = generateRandomString()
      const redirectToService = await redirectToServiceAuthPage({
        ...authInfo,
        scopes,
        authData: authDetails.authData,
        redisId,
        mode
      })
      storeAuthDetailsToRedis(
        { ...authDetails, authData: { ...authDetails?.authData, code_verifier: redirectToService?.data?.code_verifier }, mode },
        redisId
      )
      if (redirectToService?.data?.url) {
        window.location.href = redirectToService?.data?.url
      }
    } catch (error) {
      errorToast(error.message || 'Unable to authenticate, please try again.')
    }
  }

  const clientCredentials = async (authDetails: any) => {
    const dataToSend = {
      ...authDetails,
      grantType: 'client_credentials'
    }
    const res = (await getAuthToken(dataToSend))?.data
    if (res?.data?.id) {
      if (window.opener) {
        const response = {
          id: res.data?.id,
          connection_label: res.data?.connection_label,
          type: 'Auth2.0',
          meta_data: res?.data?.meta_data
        }
        window.opener.postMessage(
          response,
          mode === MiscTypes.EMBED_MODE ? process.env.REACT_APP_EMBED_URL : process.env.REACT_APP_FRONTEND_URL
        ) // Replace with your parent's origin
        window.close()
      } else {
        navigate(config.authCongoPage, { state: { message: message, button: buttonMessage, redirectUrl: redirectUrl } })
      }
    } else if (window.opener) {
      window.opener.postMessage(
        { error: 'Authentication failed. Please check your credentials and try again. If the problem persists, contact support.' },
        mode === MiscTypes.EMBED_MODE ? process.env.REACT_APP_EMBED_URL : process.env.REACT_APP_FRONTEND_URL
      )
      window.close()
    }
  }

  const handleOauth1 = async (authDetails: any) => {
    try {
      const redirectToService = await redirectToServiceAuthPage({
        ...authInfo,
        scopes,
        authData: authDetails.authData,
        mode
      })
      const redisId = redirectToService?.data?.redisId
      storeAuthDetailsToRedis({ ...authDetails, authData: { ...authDetails?.authData }, mode, type: 'Auth1' }, redisId)
      if (redirectToService?.data?.url) {
        window.location.href = redirectToService?.data?.url
      }
    } catch (error) {
      errorToast(error.message || 'Unable to authenticate, please try again.')
    }
  }

  const handleAuthVerifyAuth = async (paramsAuthData: any = null) => {
    const authDetails = {
      user_id: userid || `'${userData?.userId}'`,
      script_id: scriptid,
      project_id: projectid,
      authIdToUpdateToken: authidtoupdatetoken,
      org_id: orgid,
      service_id: authInfo?.pluginrecordid,
      auth_row_id: authInfo?.rowid,
      action_id: actionId || actionid,
      access_level_id: authLevel,
      save: save,
      authData: paramsAuthData || authData || {},
      info: {
        path: authInfo?.authenticationpaths,
        whitelist: authInfo?.whitelistdomains,
        skipwhitelistvalidation: authInfo?.skipwhitelistvalidation
      },
      meta_data: {
        description: description
      }
    }
    if (authInfo?.type === 'Auth1') {
      await handleOauth1(authDetails)
      return
    }
    switch (authInfo.granttype) {
      case 'Client Credentials':
      case 'Password Credentials':
        await clientCredentials(authDetails)
        break
      default: // 'Authorization Code, implicit'
        await authorizationCode(authDetails)
    }
  }

  const verifyButton = async () => {
    const authfields = authInfo?.authfields
    const { authentication } = authfields || {}
    if (!authentication?.fields || authentication?.fields?.length === 0) {
      return true
    }
    return authentication?.fields?.every((field) => !field.required || (authData?.[field?.key] && authData?.[field?.key] !== ''))
  }

  const handleAuthicationClick = async () => {
    setLoading(true)
    try {
      const isVerified = await verifyButton()
      if (isVerified || authInfo?.type === 'Auth1') {
        await handleAuthVerifyAuth()
        if (origin === 'setting') {
          const details = (await getCompanyBilling(orgid))?.data?.data
          await updateCompanyBilling(orgid, { ...details, discounted_app_id: authInfo?.pluginrecordid, callBack: true })
        }
      } else {
        errorToast('Please enter data in all required fields.')
      }
    } catch (e) {
      // nothing to do
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className='p-4 flex-col-center-center gap-3'>
      <Typography variant='h4' className='mt-5 w-100'>
        {isUpdate === 'true' ? 'Update' : 'Add New'} Connection
      </Typography>
      <Box
        className='flex-start-center w-100 my-2 pr-2'
        onClick={() => {
          handleBackButton()
        }}
      >
        {backButton && <ArrowBackIosIcon fontSize='small' />}
        <Avatar alt={plugData?.name} src={plugData?.iconurl} sx={{ height: '25px', width: '25px' }} className='mr-2 ' />
        <Typography variant='h5' className='mr-3'>
          {' '}
          {plugData?.name}
        </Typography>
      </Box>
      {/* <Typography variant='h5' className='mb-3'>
        Verify your connection
      </Typography> */}
      <Box className='w-100 flex-col gap-2'>
        {authInfo?.type === 'Auth2.0' &&
          authInfo?.authfields?.authentication?.fields &&
          authInfo?.authfields?.authentication?.fields?.length > 0 && (
            <AuthFields authType={authInfo?.type} authFields={authInfo?.authfields} setAuthData={setAuthData} />
          )}
        {authInfo?.type === 'Basic' && <AuthFields authType={authInfo?.type} authFields={authInfo?.authfields} setAuthData={setAuthData} />}
        <AuthTokenDescription setDescription={setDescription} />
        <AuthLevel setAuthLevel={setAuthLevel} authLevel={authLevel} />
      </Box>
      {authInfo?.rowid && (
        <Box className='w-100 flex-start-center'>
          <Button
            variant='outlined'
            className=' color-black bg-white'
            startIcon={loading ? <CircularProgress size='20px' /> : <img src={plugData?.iconurl} alt='' width='20px' />}
            onClick={handleAuthicationClick}
            disabled={loading}
          >
            Authenticate
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(AuthenticateAuth2Plugin), [
    'orgid',
    'projectid',
    'scriptid',
    'userid',
    'level',
    'authidtoupdatetoken',
    'save',
    'actionid',
    'mode',
    'isUpdate',
    'redirectUrl',
    'message',
    'buttonMessage'
  ])
)
